import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { SortableColumn } from '@components';

export const Header = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(slices.modules.order);
  const orderBy = useAppSelector(slices.modules.orderBy);

  const handleSort = (field: string) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc';
    dispatch(slices.modules.setOrder(newOrder));
    dispatch(slices.modules.setOrderBy(field));
    dispatch(slices.modules.sort({ field: field, order: newOrder }));
  };

  return (
    <TableHead>
      <TableRow>
        <SortableColumn
          name="name"
          value="Nombre"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="description"
          value="Descripción"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
        />
        <SortableColumn
          name="price"
          value="Precio base"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
          align="right"
        />
        <SortableColumn
          name="price_per_user"
          value="Precio por usuario"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
          align="right"
        />
        <SortableColumn
          name="discount"
          value="Descuento"
          order={order}
          orderBy={orderBy}
          handleSort={handleSort}
          align="right"
        />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
