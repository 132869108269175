import { useEffect } from 'react';
import { ChipWrapper, Container } from './styled';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Chip from '@mui/material/Chip';
import { Skeleton, WithoutData, Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import BusinessIcon from '@mui/icons-material/Business';
import { Header } from './header';
import { Body } from './body';
import { IEnterprise } from '@interfaces';

export const Table = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.enterprises.items);
  const searchResults = useAppSelector(slices.enterprises.searchResults);
  const searchCriteria = useAppSelector(slices.enterprises.searchCriteria);
  const loading = useAppSelector(slices.enterprises.loading);
  const loaded = useAppSelector(slices.enterprises.loaded);

  useEffect(() => {
    if (!loaded && !loading) getItems();
    return () => {
      controller.abort();
    };
  }, []);

  const getItems = async () => {
    dispatch(slices.enterprises.setLoading(true));
    await services.enterprise
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.enterprises.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar las empresas');
      });
    dispatch(slices.enterprises.setLoading(false));
  };

  const getTotalItems = () => {
    let totalItems = 0;
    if (searchCriteria === '') {
      totalItems = items.length;
    } else {
      totalItems = searchResults.length;
    }
    return (
      <Chip
        label={`${totalItems} ${totalItems === 1 ? 'empresa encontrada' : 'empresas encontradas'}`}
        size="small"
      />
    );
  };

  const buildTable = (dataList: IEnterprise[]) => (
    <TableContainer>
      <MUITable size="small" stickyHeader>
        <Header />
        <Body items={dataList} />
      </MUITable>
    </TableContainer>
  );

  return (
    <>
      <ChipWrapper>{getTotalItems()}</ChipWrapper>
      <Container>
        {loading ? (
          <Skeleton variant="table" />
        ) : searchCriteria === '' ? (
          items.length ? (
            buildTable(items)
          ) : (
            <WithoutData
              icon={<BusinessIcon />}
              title="No hay empresas"
              description="En esta sección se mostrarán las empresas que han sido agregadas"
            />
          )
        ) : searchResults.length ? (
          buildTable(searchResults)
        ) : (
          <WithoutData
            icon={<BusinessIcon />}
            title="No hay coincidencias"
            description="En esta sección se mostrarán las empresas que coincidan con los criterios de búsqueda"
          />
        )}
        <Notification data={notification} />
      </Container>
    </>
  );
};
