import { useEffect, useState } from 'react';
import { AvatarWithInfo, Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IEquipmentType, IFront, INotification, ITruck, IUser } from '@interfaces';
import { services } from '@services';
import { slices } from '@slices';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { helpers } from '@helpers';
import { settings } from '@constants';
import { OptionContainer, Information, Indicator } from './styled';

interface IProps {
  close: () => void;
  notification: INotification;
}

const AssignNewTrip = ({ close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const [options, setOptions] = useState<IFront[]>([]);
  const fronts = useAppSelector(slices.fronts.items);
  const truckDemand = useAppSelector(slices.truckDemand.items);
  const [isProcessing, setIsProcessing] = useState(false);
  const [automaticAssignment, setAutomaticAssignment] = useState(false);
  const [driver, setDriver] = useState<IUser | null>(null);
  const [truck, setTruck] = useState<ITruck | null>(null);
  const [front, setFront] = useState<IFront | null>(null);
  const [equipmentType, setEquipmentType] = useState<IEquipmentType | null>(null);
  const [cageNumber, setCageNumber] = useState('');
  const trucks = useAppSelector(slices.trucks.items);
  const loadingTrucks = useAppSelector(slices.trucks.loading);
  const loadedTrucks = useAppSelector(slices.trucks.loaded);
  const equipmentTypes = useAppSelector(slices.equipmentTypes.items);
  const loadingEquipmentTypes = useAppSelector(slices.equipmentTypes.loading);
  const loadedEquipmentTypes = useAppSelector(slices.equipmentTypes.loaded);
  const users = useAppSelector(slices.authorizedUsers.items);
  const loadingUsers = useAppSelector(slices.authorizedUsers.loading);
  const loadedUsers = useAppSelector(slices.authorizedUsers.loaded);

  useEffect(() => {
    if (!loadedTrucks && !loadingTrucks) {
      getTrucks();
    }
    if (!loadedEquipmentTypes && !loadingEquipmentTypes) {
      getEquipmentTypes();
    }
    if (!loadedUsers && !loadingUsers) {
      getUsers();
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (equipmentType === null || cageNumber.trim() === '') {
      setFront(null);
      setAutomaticAssignment(false);
    } else {
      let frontList = truckDemand.map(
        (item) => fronts.filter((front) => front.identifier === item.identifier)[0],
      );
      frontList = frontList.filter((front) => {
        if (front.equipment_type.id !== equipmentType?.id) return false;
        if (front.settings.minimum_cages > Number(cageNumber)) return false;
        if (front.settings.maximum_cages < Number(cageNumber)) return false;
        return true;
      });
      setOptions(frontList);
      if (frontList.length) {
        setFront(frontList[0]);
        setAutomaticAssignment(true);
      } else {
        setFront(null);
        setAutomaticAssignment(false);
      }
    }
  }, [equipmentType, cageNumber]);

  const getTrucks = async () => {
    dispatch(slices.trucks.setLoading(true));
    await services.truck
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.trucks.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los camiones');
      });
    dispatch(slices.trucks.setLoading(false));
  };

  const getEquipmentTypes = async () => {
    dispatch(slices.equipmentTypes.setLoading(true));
    await services.equipmentType
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.equipmentTypes.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los tipos');
      });
    dispatch(slices.equipmentTypes.setLoading(false));
  };

  const getUsers = async () => {
    dispatch(slices.authorizedUsers.setLoading(true));
    await services.user
      .authorized(controller.signal)
      .then((response) => {
        dispatch(slices.authorizedUsers.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los usuarios autorizados');
      });
    dispatch(slices.authorizedUsers.setLoading(false));
  };

  const isValid = () => {
    if (
      driver === null ||
      truck === null ||
      equipmentType === null ||
      cageNumber.trim() === '' ||
      front === null
    ) {
      setCageNumber(cageNumber.trim());
      return false;
    }
    return true;
  };

  const getData = () => {
    return {
      driver_id: driver?.id,
      truck_id: truck?.id,
      equipment_type: equipmentType?.id,
      cage_number: Number(cageNumber),
      front_id: front?.id,
      automatic_assignment: automaticAssignment,
    };
  };

  const next = async () => {
    if (isValid()) {
      setIsProcessing(true);
      await services.trip
        .assignTrip(controller.signal, getData())
        .then((response) => {
          dispatch(slices.trips.setItem(response.data));
          notification.success('Un nuevo viaje ha sido asignado');
          close();
        })
        .catch((error) => {
          switch (error.response.data.detail) {
            case 'This truck has been assigned to another driver':
              notification.warning('Este camión ya tiene un viaje asignado');
              break;
            case 'This driver already has a trip assigned':
              notification.warning('Este conductor ya tiene un viaje asignado');
              break;
            default:
              notification.warning('No fue posible asignar un nuevo viaje');
              break;
          }
        });
      setIsProcessing(false);
    } else {
      notification.warning('Debes completar todos los campos');
    }
  };

  const setNewFront = (front: IFront | null) => {
    setFront(front);
    setAutomaticAssignment(false);
  };

  const buttons = (
    <ModalButtons
      isProcessing={isProcessing}
      primaryAction={next}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Asignar viaje" close={close} actions={buttons} maxWidth="xs">
      <Autocomplete
        value={driver}
        onChange={(_event: any, newValue: IUser | null) => setDriver(newValue)}
        options={users.filter((item) => item.roles.includes(settings.roles.driver))}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Conductor"
            size="small"
            autoFocus
            sx={{ marginBottom: '10px', marginTop: '10px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              avatar={option.avatar}
              title={`${option.first_name} ${option.last_name}`}
              description={option.phone}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
      <Autocomplete
        value={truck}
        onChange={(_event: any, newValue: ITruck | null) => setTruck(newValue)}
        options={trucks}
        getOptionLabel={(option) => `${option.code} ${option.brand.name} ${option.model}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camión"
            size="small"
            sx={{ marginBottom: '20px', marginTop: '10px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <AvatarWithInfo
              title={option.code}
              description={`${option.brand.name} ${option.model}`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
      <Autocomplete
        value={equipmentType}
        onChange={(_event: any, newValue: IEquipmentType | null) => setEquipmentType(newValue)}
        options={equipmentTypes}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tipo de equipo"
            size="small"
            sx={{ marginBottom: '20px' }}
          />
        )}
        noOptionsText="No hay opciones"
      />
      <TextField
        label="Cantidad de jaulas"
        value={cageNumber}
        onChange={(event) =>
          setCageNumber(helpers.validateNumber(cageNumber, event.target.value, false))
        }
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
      <Information>
        Solo se listan los frentes que aceptan la cantidad de jaulas y tipo de equipo seleccionado
      </Information>
      <Autocomplete
        value={front}
        onChange={(_event: any, newValue: IFront | null) => setNewFront(newValue)}
        options={options}
        disabled={equipmentType === null || cageNumber.trim() === ''}
        getOptionLabel={(option) =>
          `Frente ${option.identifier} ubicado en finca ${option.farm.name}`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Frente"
            size="small"
            sx={{ marginTop: '10px', marginBottom: '20px' }}
          />
        )}
        renderOption={(props, option) => (
          <OptionContainer {...props}>
            <Indicator
              color={truckDemand.filter((item) => item.identifier === option.identifier)[0].color}
            />
            <AvatarWithInfo
              title={`Frente ${option.identifier}`}
              description={`Ubicado en finca ${option.farm.name}`}
            />
          </OptionContainer>
        )}
        noOptionsText="No hay opciones"
      />
    </Modal>
  );
};

export default AssignNewTrip;
