import { useState } from 'react';
import { Indicators } from '@interfaces';
import { ProgressIndicator } from '@components';
import Tooltip from '@mui/material/Tooltip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Wrapper, Title } from './styled';
import Detail from './detail';

interface IProps {
  data: Indicators;
}

export const QuotaCompliance = ({ data }: IProps) => {
  const [isWatching, setIsWatching] = useState(false);

  return (
    <Wrapper>
      <Tooltip title="Ver detalle" arrow>
        <ListAltIcon onClick={() => setIsWatching(true)} />
      </Tooltip>
      <ProgressIndicator
        value={data.general_quota_compliance}
        indicatorSize={150}
        titleSize={40}
        indicatorColor="primary"
      />
      <Title>Cumplimiento de cuotas</Title>
      {isWatching && <Detail data={data} close={() => setIsWatching(false)} />}
    </Wrapper>
  );
};
