import { ReactNode, useEffect, useState } from 'react';
import { Wrapper } from './styled';
import { Header } from './header';
import { Content } from './content';
import { helpers } from '@helpers';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { settings } from '@constants';
import { hooks } from '@hooks';
import { services } from '@services';

interface IProps {
  children: ReactNode;
  inSettings?: boolean;
  inAdmin?: boolean;
  inPlatform?: boolean;
}

export const Private = ({
  children,
  inSettings = false,
  inAdmin = false,
  inPlatform = false,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const modules = useAppSelector(slices.modules.items);
  const loading = useAppSelector(slices.modules.loading);
  const loaded = useAppSelector(slices.modules.loaded);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (helpers.isAuthenticated()) {
      if (
        !helpers.getPropertyInCurrentUser('enterprise') &&
        !window.location.pathname.includes('/settings/') &&
        !window.location.pathname.includes('/platform/')
      ) {
        navigate('/welcome');
      } else {
        if (helpers.getPropertyInCurrentUser('enterprise')) {
          if (!loaded && !loading) {
            getModules();
          }
        } else {
          setAuthenticated(true);
        }
      }
    } else {
      navigate('/login');
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (inSettings) dispatch(slices.settings.setInSetting());
    if (inAdmin) dispatch(slices.settings.setInAdmin());
    if (inPlatform) dispatch(slices.settings.setInPlatform());
    if (!inAdmin && !inSettings && !inPlatform) dispatch(slices.settings.setInHome());
  }, [inSettings, inAdmin, inPlatform]);

  useEffect(() => {
    if (modules.length) {
      setAuthenticated(true);
      if (
        window.location.pathname.includes('/admin') &&
        !helpers.hasRequiredRoles([settings.roles.administrator])
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/platform') &&
        !settings.superAdmins.includes(helpers.getPropertyInCurrentUser('email'))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/indicators') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.controller]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/distribution') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.controller]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/equipments/download') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([
            settings.roles.administrator,
            settings.roles.controller,
            settings.roles.downloadCoordinator,
          ]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/fronts') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.controller]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/farms') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.controller]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/drivers') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([
            settings.roles.administrator,
            settings.roles.controller,
            settings.roles.resourceManager,
          ]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/trucks') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([
            settings.roles.administrator,
            settings.roles.controller,
            settings.roles.resourceManager,
          ]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/equipments') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.resourceManager]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/equipments/types') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.resourceManager]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/brands') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.resourceManager]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/states') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([
            settings.roles.administrator,
            settings.roles.controller,
            settings.roles.resourceManager,
          ]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/drivers/current-trip') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.driver]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/transport-management/fronts/current-state') &&
        (!helpers.moduleIsActive(settings.modules.transportManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.frontBoss]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/inventory-management/indicators') &&
        (!helpers.moduleIsActive(settings.modules.inventoryManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/inventory-management/products') &&
        (!helpers.moduleIsActive(settings.modules.inventoryManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator, settings.roles.inventoryClerk]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
      if (
        window.location.pathname.includes('/inventory-management/binnacle') &&
        (!helpers.moduleIsActive(settings.modules.inventoryManagement, modules) ||
          !helpers.hasRequiredRoles([settings.roles.administrator]))
      ) {
        navigate(helpers.getHomePage(modules));
      }
    }
  }, [modules]);

  const getModules = async () => {
    dispatch(slices.modules.setLoading(true));
    await services.module
      .all(controller.signal)
      .then((response) => {
        dispatch(slices.modules.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible consultar los modulos activos');
      });
    dispatch(slices.modules.setLoading(false));
  };

  return authenticated ? (
    <Wrapper>
      <Header />
      <Content children={children} />
    </Wrapper>
  ) : null;
};
