import { useEffect, useState } from 'react';
import { Modal, ModalButtons } from '@components';
import TextField from '@mui/material/TextField';
import { INotification } from '@interfaces';
import { services } from '@services';
import { Alert } from './styled';

interface IProps {
  close: () => void;
  setIsWaiting: (value: boolean) => void;
  notification: INotification;
}

export const JoinEnterprise = ({ close, setIsWaiting, notification }: IProps) => {
  const controller = new AbortController();
  const [token, setToken] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = async () => {
    if (token.trim() === '') {
      notification.warning('Ingresa el código de empresa');
    } else {
      setIsProcessing(true);
      await services.enterprise
        .joinRequest(controller.signal, token.trim())
        .then(() => {
          notification.success('Hemos enviado tu solicitud');
          setIsWaiting(true);
          close();
        })
        .catch(() => {
          notification.warning('El código de empresa no existe');
        });
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      title="Enviar solicitud"
      close={close}
      actions={
        <ModalButtons
          isProcessing={isProcessing}
          secondaryAction={close}
          primaryAction={next}
          secondaryText="Cancelar"
          primaryText="Continuar"
        />
      }
      maxWidth="xs"
    >
      <Alert>Consulta el código de la empresa a un usuario autorizado</Alert>
      <TextField
        label="Código de empresa"
        value={token}
        onChange={(event) => setToken(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginBottom: '15px' }}
      />
    </Modal>
  );
};
