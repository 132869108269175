import { Wrapper, HideShowMenu } from './styled';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Profile } from './profile';
import { Admin } from './admin';
import { Platform } from './platform';
import { Contacts } from './contacts';
/* import { Notifications } from './notifications'; */
import { helpers } from '@helpers';
import logo from 'src/assets/logo.jpg';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { settings } from '@constants';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

export const Header = () => {
  const dispatch = useAppDispatch();
  const inAdmin = useAppSelector(slices.settings.inAdmin);
  const inPlatform = useAppSelector(slices.settings.inPlatform);
  const openMenu = useAppSelector(slices.settings.openMenu);

  const getLogo = () => {
    const enterprise = helpers.getPropertyInCurrentUser('enterprise');
    if (enterprise && enterprise['logo']) {
      return helpers.getLogo(enterprise['logo']);
    } else {
      return logo;
    }
  };

  return (
    <Wrapper>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: 'white' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={7}>
              <img src={getLogo()} style={{ float: 'left', height: '50px' }} />
              {!window.location.pathname.includes('/welcome') && (
                <Tooltip title={openMenu ? 'Ocultar menú' : 'Mostrar menú'} placement="right" arrow>
                  <HideShowMenu
                    onClick={() => dispatch(slices.settings.setOpenMenu(!openMenu))}
                    size="large"
                  >
                    {openMenu ? (
                      <MenuOpenIcon sx={{ fontSize: 27 }} />
                    ) : (
                      <MenuIcon sx={{ fontSize: 27 }} />
                    )}
                  </HideShowMenu>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={5}>
              <Profile />
              {!inAdmin &&
                helpers.getPropertyInCurrentUser('enterprise') &&
                helpers.hasRequiredRoles([settings.roles.administrator]) && <Admin />}

              {!inPlatform &&
                settings.superAdmins.includes(helpers.getPropertyInCurrentUser('email')) && (
                  <Platform />
                )}

              {helpers.getPropertyInCurrentUser('enterprise') &&
                helpers.hasRequiredRoles([
                  settings.roles.administrator,
                  settings.roles.controller,
                  settings.roles.resourceManager,
                  settings.roles.inventoryClerk,
                ]) && <Contacts />}

              {/* {helpers.getPropertyInCurrentUser('enterprise') && <Notifications />} */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Wrapper>
  );
};
