import styled, { css } from 'styled-components';
import { design } from '@constants';

export const OptionContainer = styled.div`
  padding: 5px 15px;
`;

export const Information = styled.div`
  background-color: ${design.colors.lightPrimary};
  color: ${design.colors.primary};
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0 10px;
  font-size: 12px;
`;

export const Indicator = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  right: 14px;
  position: absolute;
  ${(props: any) =>
    css`
      background: ${props.color};
    `}
`;
