import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import Grid from '@mui/material/Grid';
import { Wrapper, Content, Title, Subtitle, Value } from './styled';

export const EquipmentPendingDownload = () => {
  const [approximateAmountOfCane, setApproximateAmountOfCane] = useState(0);
  const equipments = useAppSelector(slices.equipmentsDownload.items);

  useEffect(() => {
    let total = 0;
    equipments.forEach((equipment) => {
      total += equipment.settings.load_weight * equipment.settings.cage_number;
    });
    setApproximateAmountOfCane(total);
  }, [equipments]);

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={6}>
          <Content>
            <Value>{equipments.length}</Value>
            <Subtitle>Equipos</Subtitle>
          </Content>
        </Grid>
        <Grid item xs={6}>
          <Content>
            <Value>{approximateAmountOfCane.toLocaleString()}</Value>
            <Subtitle>Toneladas</Subtitle>
          </Content>
        </Grid>
      </Grid>
      <Title>Pendiente de descarga</Title>
    </Wrapper>
  );
};
