import { design } from '@constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 67px 20px 20px;
  background-color: white;
  box-shadow: ${design.shadows.default};
  .MuiGrid-item + .MuiGrid-item {
    border-left: solid 3px ${design.colors.lightGray};
  }
`;

export const Content = styled.div`
  padding: 20px 0;
`;

export const Title = styled.div`
  padding-top: 65px;
  color: ${design.colors.darkGray};
`;

export const Subtitle = styled.div`
  color: ${design.colors.darkGray};
`;

export const Value = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: ${design.colors.primary};
`;
