import Grid from '@mui/material/Grid';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Modal } from '@components';
import { IInvoice } from '@interfaces';
import { Content, Container, State, Total, Title } from './styled';
import { helpers } from '@helpers';

interface IProps {
  selectedInvoice: IInvoice;
  close: () => void;
}

export const Detail = ({ selectedInvoice, close }: IProps) => {
  return (
    <Modal title={helpers.getMonthAndYear(selectedInvoice.created_at)} close={close} maxWidth="md">
      <Content>
        <Grid container spacing={2} style={{ paddingBottom: 30 }}>
          <Grid item xs={12} sm={6}>
            <Container>
              <Total>{Math.round(selectedInvoice.total).toLocaleString()} USD</Total>
              <Title>Total</Title>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <State paid={selectedInvoice.paid}>
                {selectedInvoice.paid ? 'Pago realizado' : 'Pago pendiente'}
              </State>
              <Title>Estado</Title>
            </Container>
          </Grid>
        </Grid>
        <TableContainer>
          <MUITable size="small" stickyHeader sx={{ minWidth: 600 }}>
            <TableHead>
              <TableRow>
                <TableCell>Concepto</TableCell>
                <TableCell align="center">Cantidad</TableCell>
                <TableCell align="right">Precio</TableCell>
                <TableCell align="right">Descuento</TableCell>
                <TableCell align="right">Subtotal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedInvoice.detail.map((item) => (
                <TableRow key={item.id} hover>
                  <TableCell>{item.concept}</TableCell>
                  <TableCell align="center">{item.amount}</TableCell>
                  <TableCell align="right">{item.price.toLocaleString()} USD</TableCell>
                  <TableCell align="right">{item.discount} %</TableCell>
                  <TableCell align="right">
                    {Math.round(item.subtotal).toLocaleString()} USD
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Content>
    </Modal>
  );
};
