import { Wrapper } from './styled';
import { Home } from './home';
import { Settings } from './settings';
import { Admin } from './admin';
import { Platform } from './platform';
import { useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { hooks } from '@hooks';

export const Menu = () => {
  const screenHeight = hooks.useScreenHeight();
  const inHome = useAppSelector(slices.settings.inHome);
  const inAdmin = useAppSelector(slices.settings.inAdmin);
  const inPlatform = useAppSelector(slices.settings.inPlatform);
  const inSetting = useAppSelector(slices.settings.inSetting);
  const openMenu = useAppSelector(slices.settings.openMenu);

  return (
    <Wrapper showDetail={openMenu} height={screenHeight}>
      {inHome && <Home />}
      {inSetting && <Settings />}
      {inAdmin && <Admin />}
      {inPlatform && <Platform />}
    </Wrapper>
  );
};
