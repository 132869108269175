import { useState } from 'react';
import Button from '@mui/material/Button';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { slices } from '@slices';
import { useAppSelector } from 'src/store/hooks';
import AssignNewTrip from './assignNewTrip';
import { Wrapper } from './styled';

export const AssignTrip = () => {
  const notification = hooks.useNotification();
  const fronts = useAppSelector(slices.fronts.items);
  const [isRequesting, setIsRequesting] = useState(false);

  return (
    <Wrapper>
      <Button
        onClick={() => setIsRequesting(true)}
        variant="contained"
        size="large"
        disabled={fronts.length === 0}
      >
        Asignar viaje
      </Button>
      {isRequesting && (
        <AssignNewTrip close={() => setIsRequesting(false)} notification={notification} />
      )}
      <Notification data={notification} />
    </Wrapper>
  );
};
