import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { MuiTelInput } from 'mui-tel-input';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import {
  Wrapper,
  Container,
  LogoWrapper,
  Logo,
  Title,
  Subtitle,
  LoginContainer,
  LoginQuestion,
  LoginLink,
} from './styled';
import logo from 'src/assets/logo.jpg';

export const Signup = () => {
  const navigate = useNavigate();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const screenHeight = hooks.useScreenHeight();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState<any>(null);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const signup = async () => {
    if (
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      birthday === null ||
      phone.trim() === '' ||
      email.trim() === '' ||
      password.trim() === '' ||
      passwordConfirmation.trim() === ''
    ) {
      notification.warning('Completa todos los campos para continuar');
    } else {
      if (password.trim() === passwordConfirmation.trim()) {
        setIsProcessing(true);
        await services.security
          .signup(
            controller.signal,
            firstName.trim(),
            lastName.trim(),
            birthday,
            phone.trim(),
            email.trim(),
            password.trim(),
          )
          .then(() => {
            navigate('/login?event=userCreated');
          })
          .catch((error) => {
            if (error.response.status === 409) {
              notification.warning('Ya existe una cuenta con el correo electrónico ingresado');
            } else {
              notification.warning('No fue posible crear tu cuenta');
            }
          });
        setIsProcessing(false);
      } else {
        notification.warning('Las contraseñas ingresadas no coinciden');
      }
    }
  };

  return (
    <Wrapper height={screenHeight}>
      <Container>
        <LogoWrapper>
          <Logo src={logo} />
        </LogoWrapper>
        <Title>Creación de cuenta</Title>
        <Subtitle>Completa todos los campos para continuar</Subtitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Nombre"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              variant="outlined"
              size="small"
              autoFocus
              fullWidth
              sx={{ marginBottom: '10px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Apellido"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ marginBottom: '10px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha de nacimiento"
                value={birthday}
                onChange={(value) => setBirthday(value)}
                slotProps={{ textField: { size: 'small' } }}
                maxDate={moment()}
                format="DD/MM/YYYY"
                sx={{ marginBottom: '25px', width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiTelInput
              label="Teléfono"
              value={phone}
              onChange={(value) => setPhone(value)}
              size="small"
              defaultCountry="GT"
              fullWidth
              sx={{ marginBottom: '25px' }}
            />
          </Grid>
        </Grid>
        <TextField
          label="Correo electrónico"
          value={email}
          onChange={(event) => setEmail(event.target.value.toLowerCase())}
          variant="outlined"
          size="small"
          fullWidth
          sx={{ marginBottom: '25px' }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Contraseña"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              sx={{ marginBottom: '30px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Confirmar contraseña"
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              sx={{ marginBottom: '30px' }}
            />
          </Grid>
        </Grid>
        <Button onClick={signup} disabled={isProcessing} variant="contained" size="large" fullWidth>
          Crear Cuenta
        </Button>
        <LoginContainer>
          <LoginQuestion>¿Ya tienes una cuenta?</LoginQuestion>
          <LoginLink onClick={() => navigate('/login')}>Iniciar sesión</LoginLink>
        </LoginContainer>
      </Container>
      <Notification data={notification} />
    </Wrapper>
  );
};
