import { useEffect, useState } from 'react';
import { Modal, Skeleton, WithoutData, Searcher } from '@components';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { INotification, ITrip, IUseAssignTrip } from '@interfaces';
import { ModalHeader, ModalTitle, SearcherWrapper, Information } from './styled';
import Chip from '@mui/material/Chip';
import { slices } from '@slices';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { Header } from './header';
import { Body } from './body';

interface IProps {
  data: IUseAssignTrip;
  close: () => void;
  notification: INotification;
}

export const Detail = ({ data, close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const fronts = useAppSelector(slices.fronts.items);
  const trips = useAppSelector(slices.trips.items);
  const searchResults = useAppSelector(slices.trips.searchResults);
  const searchCriteria = useAppSelector(slices.trips.searchCriteria);
  const [requests, setRequests] = useState<ITrip[]>([]);
  const loading = useAppSelector(slices.trips.loading);
  const [pendingConfiguration, setPendingConfiguration] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setPendingConfiguration(false);
    for (const front of fronts) {
      for (const value of Object.values(front.settings)) {
        if (value === 0 || value === '') {
          setPendingConfiguration(true);
          break;
        }
      }
    }
  }, [fronts]);

  useEffect(() => {
    if (searchCriteria === '') {
      setRequests(trips.filter((item) => item.front === null));
    } else {
      setRequests(searchResults.filter((item) => item.front === null));
    }
  }, [trips, searchCriteria]);

  const onSearch = (value: string) => {
    dispatch(slices.trips.search(value));
  };

  return (
    <Modal
      title={
        <ModalHeader>
          <ModalTitle>Solicitudes de viaje</ModalTitle>
          {trips.filter((item) => item.front === null).length > 0 && (
            <SearcherWrapper>
              <Searcher value={searchCriteria} onSearch={onSearch} />
              {requests.length > 0 && (
                <Chip
                  label={`${requests.length} ${
                    requests.length === 1 ? 'solicitud encontrada' : 'solicitudes encontradas'
                  }`}
                  style={{ fontWeight: 'normal' }}
                  size="small"
                />
              )}
              {requests.length > 0 && pendingConfiguration && (
                <Information>
                  La asignación de viajes solo esta disponible si la configuración de cada frente se
                  ha completado
                </Information>
              )}
            </SearcherWrapper>
          )}
        </ModalHeader>
      }
      close={close}
      maxWidth="sm"
      dynamicBody={false}
    >
      {loading ? (
        <Skeleton variant="table" />
      ) : requests.length > 0 ? (
        <TableContainer>
          <MUITable size="small" stickyHeader sx={{ minWidth: 500 }}>
            <Header />
            <Body
              data={data}
              items={requests}
              pendingConfiguration={pendingConfiguration}
              notification={notification}
            />
          </MUITable>
        </TableContainer>
      ) : (
        <WithoutData
          icon={<LocalShippingIcon />}
          title={`${searchCriteria === '' ? 'No hay solicitudes' : 'No hay coincidencias'}`}
          description={`${
            searchCriteria === ''
              ? 'En esta sección se mostrarán las solicitudes de viaje'
              : 'En esta sección se mostrarán las solicitudes de viaje que coincidan con los criterios de búsqueda'
          }`}
          size="medium"
        />
      )}
    </Modal>
  );
};
