import { useEffect, useState } from 'react';
import { Modal, ModalButtons, Notification, ModalConfirm } from '@components';
import TextField from '@mui/material/TextField';
import { INotification } from '@interfaces';
import { services } from '@services';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const Create = ({ close, notification }: IProps) => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const items = useAppSelector(slices.enterprises.items);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [selectedModules, setSelectedModules] = useState<number[]>([]);
  const modules = useAppSelector(slices.modules.items);
  const loading = useAppSelector(slices.modules.loading);
  const loaded = useAppSelector(slices.modules.loaded);

  useEffect(() => {
    if (!loaded && !loading) getModules();

    return () => {
      controller.abort();
    };
  }, []);

  const getModules = async () => {
    dispatch(slices.modules.setLoading(true));
    await services.module
      .available(controller.signal)
      .then((response) => {
        dispatch(slices.modules.setItems(response.data));
      })
      .catch(() => {
        notification.warning('No fue posible listar los modulos disponibles');
      });
    dispatch(slices.modules.setLoading(false));
  };

  const isDuplicated = () => {
    const results = items.filter(
      (item) => item.name.trim().toLowerCase() === name.trim().toLowerCase(),
    );
    return results.length > 0;
  };

  const validate = () => {
    if (name.trim() === '' || email.trim() === '' || selectedModules.length === 0) {
      notification.warning('Completa todos los campos');
    } else {
      if (isDuplicated()) {
        notification.warning('Ya existe una empresa con este nombre');
      } else {
        setIsConfirming(true);
      }
    }
  };

  const save = async () => {
    setIsProcessing(true);
    await services.enterprise
      .createEnterprise(controller.signal, name.trim(), email.trim(), selectedModules)
      .then((response) => {
        dispatch(slices.enterprises.setItem(response.data));
        notification.success('Se agregó la nueva empresa');
        close();
      })
      .catch((error) => {
        switch (error.response.data.detail) {
          case 'Email not registered':
            notification.warning('No existe un usuario con este correo electrónico');
            break;
          case 'User has enterprise assigned':
            notification.warning('El usuario ya está asignado a otra empresa');
            break;
          default:
            notification.warning('No fue posible agregar la nueva empresa');
            break;
        }
      });
    setIsProcessing(false);
  };

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedModules(event.target.value as number[]);
  };

  const buttons = (
    <ModalButtons
      isProcessing={false}
      primaryAction={validate}
      secondaryAction={close}
      primaryText="Continuar"
      secondaryText="Cancelar"
    />
  );

  return (
    <Modal title="Agregar empresa" close={close} actions={buttons} maxWidth="sm">
      <TextField
        label="Nombre de la empresa"
        value={name}
        onChange={(event) => setName(event.target.value)}
        variant="outlined"
        size="small"
        autoFocus
        fullWidth
        sx={{ marginTop: '10px', marginBottom: '20px' }}
      />
      <TextField
        label="Correo electrónico del administrador"
        value={email}
        onChange={(event) => setEmail(event.target.value.toLowerCase())}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ marginBottom: '10px' }}
      />
      <FormControl fullWidth size="small" sx={{ marginTop: '10px', marginBottom: '10px' }}>
        <InputLabel id="multi-select-label">Módulos</InputLabel>
        <Select
          labelId="multi-select-label"
          label="Módulos"
          multiple
          value={selectedModules}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {modules
                .filter((module) => selected.includes(module.id))
                .map((module) => (
                  <Chip key={module.id} label={module.name} size="small" />
                ))}
            </Box>
          )}
        >
          {modules.map((module) => (
            <MenuItem key={module.id} value={module.id}>
              <Checkbox checked={selectedModules.includes(module.id)} />
              <ListItemText primary={module.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isConfirming && (
        <ModalConfirm
          title="Confirmación"
          content="Al confirmar esta acción, se agregará la nueva empresa con los módulos seleccionados y se iniciará con el cobro mensual"
          primaryText="Confirmar"
          secondaryText="Cancelar"
          primaryAction={save}
          secondaryAction={() => setIsConfirming(false)}
          isProcessing={isProcessing}
        />
      )}
      <Notification data={notification} />
    </Modal>
  );
};
