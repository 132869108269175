import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  > div > div + div {
    text-align: right;
  }
  button + button {
    margin-left: 8px;
  }
`;
