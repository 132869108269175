export const settings = {
  axiosTimeout: Number(process.env.REACT_APP_AXIOS_TIMEOUT), // Milisegundos
  maximumImageSize: 5, // Megabytes
  maximumVideoSize: 10, // Megabytes
  allowedImageFormats: 'jpg,jpeg,png,gif',
  allowedVideoFormats: 'mp4,mov',
  exchangeRate: 7.7,
  roles: {
    administrator: 'ADMINISTRATOR',
    controller: 'CONTROLLER',
    frontBoss: 'FRONT_BOSS',
    resourceManager: 'RESOURCE_MANAGER',
    downloadCoordinator: 'DOWNLOAD_COORDINATOR',
    driver: 'DRIVER',
    inventoryClerk: 'INVENTORY_CLERK',
  },
  scopes: [
    { id: 1, name: 'Camiones' },
    { id: 2, name: 'Frentes' },
    { id: 3, name: 'Usuarios' },
    { id: 4, name: 'Equipos' },
  ],
  modules: {
    transportManagement: 1,
    inventoryManagement: 2,
  },
  superAdmins: ['douglas.alonzo@kafmy.com'],
};
