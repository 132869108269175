import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Option, Title, Header } from '../styled';
import Tooltip from '@mui/material/Tooltip';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { slices } from '@slices';
import { options } from './options';
import { helpers } from '@helpers';
import { hooks } from '@hooks';
import { settings } from '@constants';

export const Platform = () => {
  const dispatch = useAppDispatch();
  const screenWidth = hooks.useScreenWidth();
  const [optionSelected, setOptionSelected] = useState('');
  const openMenu = useAppSelector(slices.settings.openMenu);

  useEffect(() => {
    setOptionSelected(window.location.pathname);
  }, [window.location.pathname]);

  const openLink = () => {
    if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
  };

  return (
    <>
      {openMenu && <Header paddingtop={10}>Plataforma</Header>}
      {options.map(
        (item, index) =>
          settings.superAdmins.includes(helpers.getPropertyInCurrentUser('email')) && (
            <Link key={index} to={item.url} onClick={openLink}>
              <Tooltip title={openMenu ? '' : item.name} placement="right" arrow>
                <Option status={optionSelected === item.url ? 'selected' : ''}>
                  {item.icon}
                  {openMenu && <Title>{item.name}</Title>}
                </Option>
              </Tooltip>
            </Link>
          ),
      )}
    </>
  );
};
