import { Wrapper } from './styled';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import WebIcon from '@mui/icons-material/Web';
import { useAppDispatch } from 'src/store/hooks';
import { useNavigate } from 'react-router-dom';
import { slices } from '@slices';
import { hooks } from '@hooks';

export const Platform = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const screenWidth = hooks.useScreenWidth();

  const openLink = () => {
    if (screenWidth < 900) dispatch(slices.settings.setOpenMenu(false));
    navigate('/platform/enterprises');
  };

  return (
    <Wrapper>
      <Tooltip title="Plataforma" arrow>
        <IconButton onClick={openLink} size="large">
          <WebIcon sx={{ fontSize: 27 }} />
        </IconButton>
      </Tooltip>
    </Wrapper>
  );
};
