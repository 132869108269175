import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';

export const options = [
  {
    name: 'Regresar al inicio',
    url: '/',
    icon: <HomeIcon />,
  },
  {
    name: 'Empresas',
    url: '/platform/enterprises',
    icon: <BusinessIcon />,
  },
];
