import styled from 'styled-components';
import { design } from '@constants';

export const ModalHeader = styled.div`
  > .MuiChip-root {
    margin-top: 16px;
    width: 100%;
    background: ${design.colors.lightPrimary};
    color: ${design.colors.primary};
    height: auto;
    span {
      font-size: 12px !important;
      padding: 3px;
    }
  }
`;

export const ModalTitle = styled.div`
  font-size: 1.25rem;
`;
