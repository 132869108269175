import { AvatarWithInfo, Modal } from '@components';
import { Indicators } from '@interfaces';
import { slices } from '@slices';
import { useAppSelector } from 'src/store/hooks';
import Grid from '@mui/material/Grid';
import { ProgressIndicator } from '@components';
import { Front } from './styled';

interface IProps {
  data: Indicators;
  close: () => void;
}

const Detail = ({ data, close }: IProps) => {
  const fronts = useAppSelector(slices.fronts.items);

  return (
    <Modal title="Cumplimiento de cuotas" close={close} maxWidth="xs">
      {data.front_quota_compliance.map((item, index) => (
        <Front key={index}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <AvatarWithInfo
                title={`Frente ${item.identifier}`}
                description={`Cuota ${fronts
                  .filter((front) => front.identifier === item.identifier)[0]
                  .settings.quota.toLocaleString()} toneladas`}
              />
            </Grid>
            <Grid item xs={3} textAlign="right">
              <ProgressIndicator value={item.quota_compliance} indicatorColor="primary" />
            </Grid>
          </Grid>
        </Front>
      ))}
    </Modal>
  );
};

export default Detail;
