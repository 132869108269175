import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
  isProcessing?: boolean;
  primaryAction: () => void;
  secondaryAction: () => void;
  primaryText: string;
  secondaryText: string;
}

export const ModalButtons = ({
  isProcessing = false,
  primaryAction,
  secondaryAction,
  primaryText,
  secondaryText,
}: IProps) => {
  return (
    <>
      <Button onClick={secondaryAction} variant="outlined" size="large">
        {secondaryText}
      </Button>
      <LoadingButton
        onClick={primaryAction}
        loading={isProcessing}
        variant="contained"
        size="large"
      >
        {primaryText}
      </LoadingButton>
    </>
  );
};
