import { useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IEnterprise } from '@interfaces';
import moment from 'moment';

interface IProps {
  items: IEnterprise[];
}

export const Body = ({ items }: IProps) => {
  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{item.name}</TableCell>
          <TableCell>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
