import { useEffect, useState } from 'react';
import { Wrapper, Title } from './styled';
import { Searcher, Notification } from '@components';
import { slices } from '@slices';
import { hooks } from '@hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Create } from './create';
import Button from '@mui/material/Button';

export const Header = () => {
  const dispatch = useAppDispatch();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const items = useAppSelector(slices.enterprises.items);
  const searchCriteria = useAppSelector(slices.enterprises.searchCriteria);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const onSearch = (value: string) => {
    dispatch(slices.enterprises.search(value));
  };

  return (
    <Wrapper>
      <Button onClick={() => setIsCreating(true)} variant="outlined" size="large">
        Agregar
      </Button>
      <Searcher value={searchCriteria} onSearch={onSearch} disabled={items.length === 0} />
      <Title>Empresas</Title>
      {isCreating && <Create close={() => setIsCreating(false)} notification={notification} />}
      <Notification data={notification} />
    </Wrapper>
  );
};
