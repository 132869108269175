import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Notification } from '@components';
import { hooks } from '@hooks';
import { services } from '@services';
import { helpers } from '@helpers';
import {
  Wrapper,
  Container,
  LogoWrapper,
  Logo,
  Title,
  Subtitle,
  LoginContainer,
  LoginQuestion,
  LoginLink,
} from './styled';
import logo from 'src/assets/logo.jpg';

export const PasswordRecovery = () => {
  const navigate = useNavigate();
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const screenHeight = hooks.useScreenHeight();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [step, setStep] = useState(1);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const generateOtp = () => {
    if (email.trim() === '') {
      notification.warning('Ingresa tu correo electrónico para continuar');
    } else {
      services.security
        .generateOtp(controller.signal, email.trim())
        .then(() => {
          setStep(2);
          helpers.sleep(300000).then(() => {
            setStep((prevState) => {
              if (prevState === 2) {
                setOtp('');
                setEmail('');
                notification.warning('Tu código de seguridad ha expirado');
                return 1;
              }
              return prevState;
            });
          });
        })
        .catch(() => {
          notification.warning('No fue posible validar tu correo electrónico');
        });
    }
  };

  const validateOtp = () => {
    if (otp.trim() === '') {
      notification.warning('Ingresa el código de seguridad');
    } else {
      services.security
        .validateOtp(controller.signal, email.trim(), otp.trim())
        .then(() => {
          setStep(3);
        })
        .catch(() => {
          notification.warning('El código ingresado no es correcto');
        });
    }
  };

  const registerPassword = () => {
    if (password.trim() === '' || passwordConfirmation.trim() === '') {
      notification.warning('Completa todos los campos para continuar');
    } else {
      if (password === passwordConfirmation) {
        services.security
          .registerPassword(controller.signal, email.trim(), password.trim(), otp.trim())
          .then(() => {
            navigate('/login?event=passwordChanged');
          })
          .catch(() => {
            notification.warning('No fue posible registrar tu nueva contraseña');
          });
      } else {
        notification.warning('Las contraseñas ingresadas no coinciden');
      }
    }
  };

  return (
    <Wrapper height={screenHeight}>
      <Container>
        <LogoWrapper>
          <Logo src={logo} />
        </LogoWrapper>
        {step === 1 && (
          <>
            <Title>Recuperar contraseña</Title>
            <Subtitle>Ingresa tu correo electrónico para continuar</Subtitle>
            <TextField
              label="Correo electrónico"
              value={email}
              onChange={(event) => setEmail(event.target.value.toLowerCase())}
              variant="outlined"
              size="small"
              autoFocus
              fullWidth
              sx={{ marginBottom: '25px' }}
            />
            <Button onClick={generateOtp} variant="contained" size="large" fullWidth>
              Recuperar Contraseña
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Title>Validación de seguridad</Title>
            <Subtitle>Ingresa el código enviado a tu correo electrónico</Subtitle>
            <TextField
              label="Código de seguridad"
              value={otp}
              onChange={(event) => setOtp(event.target.value)}
              variant="outlined"
              size="small"
              autoFocus
              fullWidth
              sx={{ marginBottom: '25px' }}
            />
            <Button onClick={validateOtp} variant="contained" size="large" fullWidth>
              Validar
            </Button>
          </>
        )}
        {step === 3 && (
          <>
            <Title>Nueva contraseña</Title>
            <Subtitle>Ingresa tu nueva contraseña y confirmala para continuar</Subtitle>
            <TextField
              label="Contraseña"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              variant="outlined"
              size="small"
              type="password"
              autoFocus
              fullWidth
              sx={{ marginBottom: '20px' }}
            />
            <TextField
              label="Confirmar contraseña"
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              variant="outlined"
              size="small"
              type="password"
              fullWidth
              sx={{ marginBottom: '25px' }}
            />
            <Button onClick={registerPassword} variant="contained" size="large" fullWidth>
              Registrar Contraseña
            </Button>
          </>
        )}
        <LoginContainer>
          <LoginQuestion>¿Has recordado tu contraseña?</LoginQuestion>
          <LoginLink onClick={() => navigate('/login')}>Iniciar sesión</LoginLink>
        </LoginContainer>
      </Container>
      <Notification data={notification} />
    </Wrapper>
  );
};
