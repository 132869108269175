import { useEffect, useState } from 'react';
import { ITripSettings, ITruck, ITruckForDownload } from '@interfaces';
import { Wrapper } from './styled';
import { AvatarWithInfo, ProgressIndicator } from '@components';
import moment from 'moment';
import { design } from '@constants';

interface IProps {
  settings: ITripSettings | null;
  isWaiting?: boolean;
  truck: ITruck | ITruckForDownload;
  driver?: string;
  titleSize: number;
  indicatorSize: number;
  variant: 'Vertical' | 'Horizontal';
}

export const TimeIndicator = ({
  settings,
  isWaiting = false,
  truck,
  driver,
  titleSize,
  indicatorSize,
  variant,
}: IProps) => {
  const [percentageTimeElapsed, setPercentageTimeElapsed] = useState(0);
  const [formattedTimeLeft, setFormattedTimeLeft] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    updateProgressIndicator();
    const intervalId = setInterval(updateProgressIndicator, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [settings]);

  const updateProgressIndicator = () => {
    let diffInMilliseconds = 0;
    let timeAllowedInMilliseconds = 0;
    if (settings) {
      let currentMoment = moment();
      if (truck && 'state' in truck && truck.state.name !== 'Activo') {
        currentMoment = moment(truck.state_date);
      }
      if (settings.front_arrival_datetime === '') {
        setTitle('Llegada al frente');
        diffInMilliseconds =
          currentMoment.diff(moment(settings.mill_departure_datetime)) -
          settings.front_arrival_downtime;
        timeAllowedInMilliseconds = getMilliseconds(settings.time_to_go);
      }
      if (settings.front_arrival_datetime !== '' && settings.front_departure_datetime === '') {
        setTitle('Salida del frente');
        diffInMilliseconds =
          currentMoment.diff(moment(settings.front_arrival_datetime)) -
          settings.front_departure_downtime;
        timeAllowedInMilliseconds = getMilliseconds(settings.time_to_load);
      }
      if (settings.front_departure_datetime !== '' && settings.mill_arrival_datetime === '') {
        setTitle('Llegada al ingenio');
        diffInMilliseconds =
          currentMoment.diff(moment(settings.front_departure_datetime)) -
          settings.mill_arrival_downtime;
        timeAllowedInMilliseconds = getMilliseconds(settings.time_to_return);
      }
      if (settings.mill_arrival_datetime !== '' && settings.download_datetime === '') {
        setTitle(isWaiting ? 'En espera' : 'Descargando');
        diffInMilliseconds = currentMoment.diff(moment(settings.mill_arrival_datetime));
      }
      let percentage = 0;
      if (diffInMilliseconds > timeAllowedInMilliseconds) {
        if (settings.mill_arrival_datetime !== '' && settings.download_datetime === '') {
          setFormattedTimeLeft(formatDecimalTime(getHours(diffInMilliseconds)));
        } else {
          setFormattedTimeLeft(
            formatDecimalTime(getHours(diffInMilliseconds - timeAllowedInMilliseconds)),
          );
        }
      } else {
        setFormattedTimeLeft(
          formatDecimalTime(getHours(timeAllowedInMilliseconds - diffInMilliseconds)),
        );
        percentage = 100 - (diffInMilliseconds * 100) / timeAllowedInMilliseconds;
      }
      if (settings.mill_arrival_datetime !== '' && settings.download_datetime === '') {
        setDescription(
          `${settings.priority_download ? 'Descarga con prioridad' : 'Descarga normal'}`,
        );
        setColor(settings.priority_download ? design.colors.red : design.colors.green);
      } else {
        if (percentage === 0) {
          setDescription('Fuera de tiempo');
          setColor(design.colors.red);
        } else {
          setDescription('En tiempo');
          setColor(design.colors.green);
        }
      }
      setPercentageTimeElapsed(percentage);
    }
  };

  const formatDecimalTime = (decimalTime: number) => {
    let hours = Math.floor(decimalTime);
    let minutes = Math.round((decimalTime % 1) * 60);
    if (minutes === 60) {
      minutes = 0;
      hours++;
    }
    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  };

  const getMilliseconds = (decimalTime: number) => decimalTime * 60 * 60 * 1000;

  const getHours = (milliseconds: number) => milliseconds / (1000 * 60 * 60);

  return (
    <Wrapper variant={variant}>
      <ProgressIndicator
        value={percentageTimeElapsed}
        title={formattedTimeLeft}
        suffix=""
        titleSize={titleSize}
        indicatorSize={indicatorSize}
        countdown={true}
      />
      <AvatarWithInfo
        title={title}
        description={description}
        descriptionColor={color}
        tooltipTitle={driver || undefined}
        tooltipDescription={truck && !('state' in truck) ? `Camión ${truck.code}` : undefined}
      />
    </Wrapper>
  );
};
