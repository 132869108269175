import { useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableOptions } from '@components';
import { IInvoice } from '@interfaces';
import { State } from './styled';
import { Detail } from './detail';
import { helpers } from '@helpers';

interface IProps {
  items: IInvoice[];
}

export const Body = ({ items }: IProps) => {
  const [isViewingDetail, setIsViewingDetail] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);

  const tableOptions = (item: IInvoice) => (
    <TableOptions
      row={item}
      options={[
        {
          title: 'Ver detalle',
          onClick: viewDetail,
        },
      ]}
    />
  );

  const viewDetail = (value: IInvoice) => {
    setSelectedInvoice(value);
    setIsViewingDetail(true);
  };

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id} hover>
          <TableCell>{helpers.getMonthAndYear(item.created_at)}</TableCell>
          <TableCell align="center">
            {item.detail.filter((item) => item.concept.includes('Suscripción')).length}
          </TableCell>
          <TableCell align="right">{Math.round(item.total).toLocaleString()} USD</TableCell>
          <TableCell align="center">
            <State paid={item.paid}>{item.paid ? 'Pago realizado' : 'Pago pendiente'}</State>
          </TableCell>
          {tableOptions(item)}
        </TableRow>
      ))}
      {isViewingDetail && selectedInvoice && (
        <Detail selectedInvoice={selectedInvoice} close={() => setIsViewingDetail(false)} />
      )}
    </TableBody>
  );
};
