import styled from 'styled-components';
import { design } from '@constants';

export const Content = styled.div`
  margin-bottom: 15px;
`;

export const CropperWrapper = styled.div`
  position: relative;
  height: 200px;
  .reactEasyCrop_Container {
    border-radius: 10px 10px 0px 0px;
    background-color: ${design.colors.lightBlue};
  }
  .reactEasyCrop_CropArea {
    color: ${design.colors.mediaControlsBackground};
  }
  div > div {
    border-radius: 10px;
  }
`;
