import { useEffect, useState } from 'react';
import { Wrapper } from './styled';
import { Header, Notification } from '@components';
import { Content } from './content';
import { JoinEnterprise } from './joinEnterprise';
import { useAppDispatch } from 'src/store/hooks';
import { slices } from '@slices';
import { helpers } from '@helpers';
import { hooks } from '@hooks';
import { useNavigate } from 'react-router-dom';

export const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notification = hooks.useNotification();
  const screenHeight = hooks.useScreenHeight();
  const [isJoining, setIsJoining] = useState(false);
  const [isWaiting, setIsWaiting] = useState(true);

  useEffect(() => {
    if (!helpers.isAuthenticated()) {
      navigate('/login');
    }
    dispatch(slices.settings.setInHome());
  }, []);

  return (
    <>
      <Header />
      <Wrapper height={screenHeight}>
        <Content
          setIsJoining={() => setIsJoining(true)}
          isWaiting={isWaiting}
          setIsWaiting={setIsWaiting}
        />
        {isJoining && (
          <JoinEnterprise
            close={() => setIsJoining(false)}
            setIsWaiting={setIsWaiting}
            notification={notification}
          />
        )}
      </Wrapper>
      <Notification data={notification} />
    </>
  );
};
