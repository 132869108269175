import { Indicators } from '@interfaces';
import { Wrapper, Title } from './styled';
import { ProgressIndicator } from '@components';

interface IProps {
  data: Indicators;
}

export const SelfAssigned = ({ data }: IProps) => {
  return (
    <Wrapper>
      <ProgressIndicator
        value={data.percentage_of_self_assigned}
        indicatorSize={100}
        titleSize={25}
        indicatorColor="primary"
      />
      <Title>Cumplimiento de la autoasignación de viajes</Title>
    </Wrapper>
  );
};
