import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import pendingUsers from './slices/pendingUsers';
import authorizedUsers from './slices/authorizedUsers';
import roles from './slices/roles';
import farms from './slices/farms';
import fronts from './slices/fronts';
import states from './slices/states';
import trucks from './slices/trucks';
import settings from './slices/settings';
import links from './slices/links';
import trip from './slices/trip';
import trips from './slices/trips';
import enterpriseSettings from './slices/enterpriseSettings';
import equipments from './slices/equipments';
import equipmentsDownload from './slices/equipmentsDownload';
import brands from './slices/brands';
import equipmentTypes from './slices/equipmentTypes';
import frontCompliance from './slices/frontCompliance';
import front from './slices/front';
import truckDemand from './slices/truckDemand';
import indicators from './slices/indicators';
import modules from './slices/modules';
import invoices from './slices/invoices';
import products from './slices/products';
import imIndicators from './slices/inventoryManagement/indicators';
import enterprises from './slices/enterprises';

export const store = configureStore({
  reducer: {
    pendingUsers: pendingUsers,
    authorizedUsers: authorizedUsers,
    roles: roles,
    farms: farms,
    fronts: fronts,
    states: states,
    trucks: trucks,
    settings: settings,
    links: links,
    trip: trip,
    trips: trips,
    enterpriseSettings: enterpriseSettings,
    equipments: equipments,
    equipmentsDownload: equipmentsDownload,
    brands: brands,
    equipmentTypes: equipmentTypes,
    frontCompliance: frontCompliance,
    front: front,
    truckDemand: truckDemand,
    indicators: indicators,
    modules: modules,
    invoices: invoices,
    products: products,
    imIndicators: imIndicators,
    enterprises: enterprises,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
