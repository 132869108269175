import { hooks } from '@hooks';
import { Wrapper, Content, Title, Subtitle } from './styled';

interface IProps {
  icon: any;
  title: string;
  description: string;
  size?: 'large' | 'medium' | 'small';
}

export const WithoutData = ({ icon, title, description, size = 'large' }: IProps) => {
  const screenWidth = hooks.useScreenWidth();

  return (
    <Wrapper>
      <Content size={screenWidth < 600 ? 'small' : size}>
        {icon}
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
      </Content>
    </Wrapper>
  );
};
