import { AvatarWithInfo, Modal } from '@components';
import { Indicators } from '@interfaces';
import Grid from '@mui/material/Grid';
import { ProgressIndicator } from '@components';
import { Front } from './styled';

interface IProps {
  data: Indicators;
  close: () => void;
}

const Detail = ({ data, close }: IProps) => {
  return (
    <Modal title="Cumplimiento del tiempo de viaje cargado" close={close} maxWidth="xs">
      {data.front_time_compliance.time_to_return.map((item, index) => (
        <Front key={index}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <AvatarWithInfo title={`Frente ${item.identifier}`} />
            </Grid>
            <Grid item xs={3} textAlign="right">
              <ProgressIndicator value={item.time_compliance} indicatorColor="primary" />
            </Grid>
          </Grid>
        </Front>
      ))}
    </Modal>
  );
};

export default Detail;
