import { useEffect, useState } from 'react';
import {
  Wrapper,
  Title,
  Description,
  Option,
  Icon,
  OptionTitle,
  OptionDescription,
  Alert,
} from './styled';
import { Notification } from '@components';
import { hooks } from '@hooks';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { services } from '@services';
import { design } from '@constants';

interface IProps {
  setIsJoining: () => void;
  isWaiting: boolean;
  setIsWaiting: (value: boolean) => void;
}

export const Content = ({ setIsJoining, isWaiting, setIsWaiting }: IProps) => {
  const controller = new AbortController();
  const notification = hooks.useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAuthorizationStatus();

    return () => {
      controller.abort();
    };
  }, []);

  const getAuthorizationStatus = async () => {
    setLoading(true);
    await services.user
      .getAuthorizationStatus(controller.signal)
      .then((response) => {
        setIsWaiting(response.data.waiting_authorization);
      })
      .catch(() => {
        notification.warning('No fue posible verificar el estado de autorización');
      });
    setLoading(false);
  };

  return (
    <Wrapper>
      <Title>Te damos la bienvenida</Title>
      <Description>
        Ahora puedes solicitar la autorización de tu usuario de forma fácil y rápida
      </Description>
      <Option onClick={setIsJoining} iswaiting={isWaiting}>
        <Icon>
          <AdminPanelSettingsIcon />
        </Icon>
        <OptionTitle>Enviar solicitud</OptionTitle>
        <OptionDescription>
          Solicita la autorización de tu usuario ingresando el código de la empresa
        </OptionDescription>
      </Option>
      <Alert style={{ color: design.colors.green }}>
        {loading && 'Verificando estado de autorización'}
      </Alert>
      <Alert style={{ color: design.colors.orange }}>
        {!loading && isWaiting && 'Esperando autorización'}
      </Alert>
      <Notification data={notification} />
    </Wrapper>
  );
};
