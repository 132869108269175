import { axiosInstance } from './axios';
import { api } from '@constants';
import { IAssignFront, IAssignTrip, IRequestTrip } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.trips}`, { signal: signal });
};

const allForDownload = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.trips}/download`, { signal: signal });
};

const current = (signal: AbortSignal) => {
  return axiosInstance.get(`${api.trips}/current`, { signal: signal });
};

const request = (signal: AbortSignal, trip: IRequestTrip) => {
  return axiosInstance.post(`${api.trips}/request`, trip, { signal: signal });
};

const assignFront = (signal: AbortSignal, data: IAssignFront) => {
  return axiosInstance.post(`${api.trips}/assign-front`, data, { signal: signal });
};

const assignTrip = (signal: AbortSignal, data: IAssignTrip) => {
  return axiosInstance.post(`${api.trips}/assign-trip`, data, { signal: signal });
};

const del = (signal: AbortSignal, tripId: number) => {
  return axiosInstance.delete(`${api.trips}/${tripId}`, { signal: signal });
};

const notify = (signal: AbortSignal, tripId: number, generatedEvent: string) => {
  return axiosInstance.put(`${api.trips}/${generatedEvent}/${tripId}`, { signal: signal });
};

const changeFront = (signal: AbortSignal, tripId: number, frontId: number) => {
  const data = {
    trip_id: tripId,
    front_id: frontId,
  };
  return axiosInstance.put(`${api.trips}/change-front`, data, { signal: signal });
};

const transfer = (signal: AbortSignal, tripId: number, driverId: number) => {
  const data = {
    trip_id: tripId,
    driver_id: driverId,
  };
  return axiosInstance.put(`${api.trips}/transfer`, data, { signal: signal });
};

const notifyDepartureFront = (
  signal: AbortSignal,
  tripId: number,
  equipmentId: number,
  equipmentTypeId: number,
  cageNumber: number,
) => {
  const data = {
    trip_id: tripId,
    equipment_id: equipmentId,
    equipment_type_id: equipmentTypeId,
    cage_number: cageNumber,
  };
  return axiosInstance.put(`${api.trips}/notify-departure-front`, data, { signal: signal });
};

const assignEquipment = (signal: AbortSignal, tripId: number, truckId: number) => {
  const data = {
    trip_id: tripId,
    truck_id: truckId,
  };
  return axiosInstance.put(`${api.trips}/assign-equipment`, data, { signal: signal });
};

export const trip = {
  all,
  allForDownload,
  current,
  request,
  assignFront,
  assignTrip,
  del,
  notify,
  changeFront,
  transfer,
  notifyDepartureFront,
  assignEquipment,
};
